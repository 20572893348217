<template>
    <div class="flex items-center gap-2 mb-2">
        <Icon name="magnifying-glass" class="size-4 shrink-0 opacity-50" />
        <ComboboxInput v-bind="{ ...forwardedProps, ...$attrs }" auto-focus :class="inputClass" />
        <slot />
    </div>
</template>
<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { ComboboxInput, type ComboboxInputProps, useForwardProps } from "radix-vue";

defineOptions({ inheritAttrs: false });

const props = defineProps<ComboboxInputProps & { class?: HTMLAttributes["class"] }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);

const inputClass = computed(() =>
    cn(
        "flex w-full bg-transparent py-1 text-sm placeholder:text-gray-500 disabled:cursor-not-allowed disabled:opacity-50 focus:ring-transparent",
        props.class
    )
);
</script>
